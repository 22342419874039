import axios from "axios";
import routeConstants from "../constants/route";

// Enable sending cookies with requests
axios.defaults.withCredentials = true;

const API = axios.create({
  baseURL: routeConstants.BASE_URL,
  withCredentials: true,
});

export const fetchClientById = (id) =>
  API.get(`/${routeConstants.ROUTE.CLIENT}/${id}`);

export const updateGeneralInfo = (payload) =>
  API.put(`/${routeConstants.ROUTE.CLIENT}/general`, payload);

export const updateProfilePicture = (id, payload) =>
  API.put(`/${routeConstants.ROUTE.CLIENT}/profile-picture/${id}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const makeBooking = (payload) =>
  API.post(`/${routeConstants.ROUTE.CLIENT}/booking`, payload);

export const fetchBookingsByClientId = (id) =>
  API.get(`/${routeConstants.ROUTE.CLIENT}/bookings/${id}`);

export const cancelBookingById = (id) =>
  API.post(`/${routeConstants.ROUTE.CLIENT}/booking/cancel/${id}`);

export const createRestaurant = (payload) =>
  API.post(
    `/${routeConstants.ROUTE.CLIENT}/${routeConstants.ROUTE.RESTAURANT}/sign-up`,
    payload
  );

export const restaurantLogin = (payload) =>
  API.post(
    `/${routeConstants.ROUTE.CLIENT}/${routeConstants.ROUTE.RESTAURANT}/login`,
    payload
  );
