import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Modal } from "antd";
import { extractSrcLink } from "../../../../utils/common";
import { addArtistMedia } from "../../../../actions/artist";
import {
  selectLoggedInArtist,
  setAddArtistMediaErrorMessage,
} from "../../../../reducers/artist";

const AddArtistMediaModal = ({ open, closeModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const artistId = useSelector(selectLoggedInArtist)?.id;

  const handleSubmit = (values) => {
    const extractedSrcLink = extractSrcLink(values?.link);

    if (!_.isEmpty(extractedSrcLink)) {
      dispatch(
        addArtistMedia({
          link: extractedSrcLink,
          artistId,
        })
      );
    } else {
      dispatch(
        setAddArtistMediaErrorMessage(
          "Could not extract link from the embedded link."
        )
      );
    }

    closeModal();
  };

  return (
    <Modal
      open={open}
      title="Add Media"
      okText="Add"
      cancelText="Cancel"
      okButtonProps={{
        autoFocus: true,
        htmlType: "submit",
      }}
      onCancel={closeModal}
      destroyOnClose
      modalRender={(dom) => (
        <Form
          layout="vertical"
          form={form}
          name="addArtistMediaModal"
          requiredMark="optional"
          clearOnDestroy
          onFinish={handleSubmit}
        >
          {dom}
        </Form>
      )}
    >
      <Form.Item
        name="link"
        label="Embedded Link"
        rules={[
          {
            required: true,
            message: "Please input the embedded youtube link!",
          },
        ]}
        extra={
          <div>
            <p>Follow the steps below to get the embedded link.</p>
            <p>- Go to your YouTube channel.</p>
            <p>
              - Open the video you would like to upload in The Artist Network.
            </p>
            <p>
              - Click on <strong>Share</strong>.
            </p>
            <p>
              - Click on <strong>Embedded</strong>.
            </p>
            <p>
              - Copy the Embedded link. It should look like{" "}
              <strong>&lt;iframe...</strong>
            </p>
          </div>
        }
      >
        <Input.TextArea rows={5} placeholder="<iframe....." />
      </Form.Item>
    </Modal>
  );
};

export default AddArtistMediaModal;
