import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Modal, Space, Typography } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CompassOutlined,
  ExclamationCircleFilled,
  UserOutlined,
} from "@ant-design/icons";
import {
  constructAddress,
  createFullName,
  formatToRupee,
  formatHumanReadableDate,
} from "../../../utils/common";
import { BOOKING_STATUS } from "../../../constants/common";
import { cancelBookingById, confirmBookingById } from "../../../actions/artist";

const Booking = ({ booking }) => {
  const dispatch = useDispatch();
  const address = booking?.additionalInfo?.address;
  const artist = booking?.additionalInfo?.artist;
  const client = booking?.additionalInfo?.client;
  const bookingStatus = booking?.status;
  const status = BOOKING_STATUS[bookingStatus];
  const eventType = booking?.additionalInfo?.eventType;
  const startTime = booking?.startTime;
  const endTime = booking?.endTime;
  const createdAt = booking?.createdAt;
  const updatedAt = booking?.updatedAt;
  const price = booking?.additionalInfo?.price;

  const handleCancelBooking = () => {
    Modal.confirm({
      title: "Are you sure cancel this booking?",
      icon: <ExclamationCircleFilled />,
      content:
        "This will cancel the booking. You advance payment may not be refunded back entirely!",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        dispatch(
          cancelBookingById({
            artistId: artist?.id,
            bookingId: booking?.id,
          })
        );
      },
      onCancel() {},
    });
  };

  const handleBookingConfirmation = () => {
    Modal.confirm({
      title: "Are you sure to confirm this booking?",
      icon: <ExclamationCircleFilled />,
      content:
        "This will confirm the booking. Once confirmed, please make sure you are available at the event timings. Failing to perform more than 3 times might regard to profile taken off of platform!",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        dispatch(
          confirmBookingById({
            artistId: artist?.id,
            bookingId: booking?.id,
          })
        );
      },
      onCancel() {},
    });
  };

  const isCancelBookingDisabled = () => {
    return bookingStatus !== BOOKING_STATUS.PENDING.id;
  };

  const isConfirmBookingDisabled = () => {
    return bookingStatus !== BOOKING_STATUS.PENDING.id;
  };

  return (
    <div className="w-full p-6 rounded-md shadow">
      <div className="flex justify-between">
        <Typography.Title level={5} className="mb-0">
          {eventType?.name} Booking
        </Typography.Title>
        <Typography.Paragraph className="mb-0 font-extrabold">
          {formatToRupee(price)}
        </Typography.Paragraph>
      </div>
      <Divider />
      <Typography.Paragraph className="mb-0" type="secondary">
        <CompassOutlined /> {constructAddress(address)}
      </Typography.Paragraph>
      <Typography.Paragraph className="mb-0" type="secondary">
        <UserOutlined /> {createFullName(client)}
      </Typography.Paragraph>
      <Divider />
      <Typography.Paragraph className="mb-0" type="secondary">
        <ClockCircleOutlined /> Event Time -
      </Typography.Paragraph>
      <Typography.Paragraph className="mb-0" type="secondary">
        {formatHumanReadableDate(startTime)} to{" "}
        {formatHumanReadableDate(endTime)}
      </Typography.Paragraph>
      <Divider />
      <Typography.Paragraph className="mb-0" type="secondary">
        Booked on {formatHumanReadableDate(createdAt)}
      </Typography.Paragraph>
      <Typography.Paragraph className="mb-0" type="secondary">
        Last updated on {formatHumanReadableDate(updatedAt)}
      </Typography.Paragraph>
      <Divider />
      <div className="flex justify-center w-full">
        <Typography.Paragraph
          className="px-3 py-2 mb-0 rounded w-fit"
          style={{
            backgroundColor: status?.color?.background || "#ffffff", // Set background color
            color: status?.color?.text || "#000000", // Set text color
          }}
        >
          {status?.name}
        </Typography.Paragraph>
      </div>
      <Divider />
      <div className="flex items-center justify-end gap-2 h-fit">
        <Button
          disabled={isCancelBookingDisabled()}
          danger
          onClick={handleCancelBooking}
        >
          Cancel Booking
        </Button>
        <Button
          type="primary"
          disabled={isConfirmBookingDisabled()}
          onClick={handleBookingConfirmation}
        >
          Confirm Booking
        </Button>
      </div>
    </div>
  );
};

export default Booking;
