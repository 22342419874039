import { createSlice } from "@reduxjs/toolkit";
import messageConstants from "../constants/message";
import * as clientActions from "../actions/client";

const initialLoadingState = {
  client: false,
  createRestaurant: false,
  restaurantLogin: false,
  editProfile: false,
  booking: false,
  fetchBookings: false,
};

const initialErrorState = {
  client: null,
  createRestaurant: null,
  restaurantLogin: null,
  updateGeneralInfo: null,
  makeBooking: null,
  fetchBookings: null,
};

const initialSuccessState = {
  createRestaurant: null,
  restaurantLogin: null,
  updateGeneralInfo: null,
  booking: null,
};

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    client: null,
    loggedInClient: null,
    bookings: [],
    loading: initialLoadingState,
    error: initialErrorState,
    success: initialSuccessState,
  },
  reducers: {
    setLoggedInClient: (state, action) => {
      state.loggedInClient = action.payload;
    },
    // Clearing client info
    clearLoggedInClient: (state) => {
      state.loggedInClient = null;
    },

    // Clearing error messages
    clearClientErrorMessage: (state) => {
      state.error.client = null;
    },
    clearCreateRestaurantError: (state) => {
      state.error.createRestaurant = null;
    },
    clearRestaurantLoginErrorMessage: (state) => {
      state.error.restaurantLogin = null;
    },
    clearClientUpdateGeneralInfoErrorMessage: (state) => {
      state.error.updateGeneralInfo = null;
    },
    clearMakeBookingErrorMessage: (state) => {
      state.error.makeBooking = null;
    },
    clearFetchBookingsErrorMessage: (state) => {
      state.error.fetchBookings = null;
    },
    clearCancelBookingByClientErrorMessage: (state) => {
      state.error.cancelBooking = null;
    },

    // Clearing success messages
    clearCreateRestaurantSuccess: (state) => {
      state.success.createRestaurant = null;
    },
    clearRestaurantLoginSuccessMessage: (state) => {
      state.success.restaurantLogin = null;
    },
    clearClientUpdateGeneralInfoSuccessMessage: (state) => {
      state.success.updateGeneralInfo = null;
    },
    clearCancelBookingByClientSuccessMessage: (state) => {
      state.success.cancelBooking = null;
    },
  },
  extraReducers(builder) {
    builder
      // Creating restaurant actions
      .addCase(clientActions.createRestaurant.pending, (state) => {
        state.loading.createRestaurant = true;
      })
      .addCase(clientActions.createRestaurant.fulfilled, (state) => {
        state.loading.createRestaurant = false;
        state.error.createRestaurant = null;
        state.success.createRestaurant =
          messageConstants.DEFAULT_SUCCESS.CREATE_RESTAURANT;
      })
      .addCase(clientActions.createRestaurant.rejected, (state, action) => {
        state.loading.createRestaurant = false;
        state.success.createRestaurant = null;
        state.error.createRestaurant =
          action?.payload?.data?.error ||
          messageConstants.DEFAULT_ERROR.CREATE_RESTAURANT;
      })

      // Logging in restaurant actions
      .addCase(clientActions.restaurantLogin.pending, (state) => {
        state.loading.restaurantLogin = true;
      })
      .addCase(clientActions.restaurantLogin.fulfilled, (state, action) => {
        state.loading.restaurantLogin = false;
        state.loggedInClient = action?.payload?.client;
      })
      .addCase(clientActions.restaurantLogin.rejected, (state, action) => {
        state.loading.restaurantLogin = false;
        state.error.restaurantLogin =
          action?.payload?.data?.error ||
          messageConstants.DEFAULT_ERROR.RESTAURANT_LOGIN;
      })

      // Fetching an client
      .addCase(clientActions.fetchClientById.pending, (state) => {
        state.loading.client = true;
      })
      .addCase(clientActions.fetchClientById.fulfilled, (state, action) => {
        state.loading.client = false;
        state.loggedInClient = action.payload?.client;
      })
      .addCase(clientActions.fetchClientById.rejected, (state, action) => {
        state.loading.client = false;
        state.error.client =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.FETCH_CLIENT_BY_ID;
      })

      // Updating General Information
      .addCase(clientActions.updateGeneralInfo.pending, (state) => {
        state.loading.editProfile = true;
      })
      .addCase(clientActions.updateGeneralInfo.fulfilled, (state) => {
        state.loading.editProfile = false;
        state.success.updateGeneralInfo =
          messageConstants.DEFAULT_SUCCESS.UPDATE_GENERAL_INFO;
      })
      .addCase(clientActions.updateGeneralInfo.rejected, (state, action) => {
        state.loading.editProfile = false;
        state.error.updateGeneralInfo =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.UPDATE_GENERAL_INFO;
      })

      // Updating Profile Picture
      .addCase(clientActions.updateProfilePicture.pending, (state) => {
        state.loading.editProfile = true;
      })
      .addCase(
        clientActions.updateProfilePicture.fulfilled,
        (state, action) => {
          state.loading.editProfile = false;
          state.success.updateGeneralInfo =
            action?.payload?.message ||
            messageConstants.DEFAULT_ERROR.UPDATE_GENERAL_INFO;
        }
      )
      .addCase(clientActions.updateProfilePicture.rejected, (state, action) => {
        state.loading.editProfile = false;
        state.error.updateGeneralInfo =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.UPDATE_GENERAL_INFO;
      })

      .addCase(clientActions.makeBooking.pending, (state) => {
        state.loading.booking = true;
      })
      .addCase(clientActions.makeBooking.fulfilled, (state) => {
        state.loading.booking = false;
      })
      .addCase(clientActions.makeBooking.rejected, (state, action) => {
        state.loading.booking = false;
        state.error.makeBooking =
          action?.payload?.message || messageConstants.DEFAULT_ERROR.BOOKING;
      })

      // Fetching Bookings
      .addCase(clientActions.fetchBookingsByClientId.pending, (state) => {
        state.loading.fetchBookings = true;
        state.error.fetchBookings = null;
      })
      .addCase(
        clientActions.fetchBookingsByClientId.fulfilled,
        (state, action) => {
          state.loading.fetchBookings = false;
          state.bookings = action?.payload?.bookings;
        }
      )
      .addCase(
        clientActions.fetchBookingsByClientId.rejected,
        (state, action) => {
          state.loading.fetchBookings = false;
          state.error.fetchBookings =
            action?.payload?.message ||
            messageConstants.DEFAULT_ERROR.FETCH_BOOKINGS;
        }
      )
      // Cancel Booking
      .addCase(clientActions.cancelBookingById.pending, (state) => {
        state.loading.fetchBookings = true;
      })
      .addCase(clientActions.cancelBookingById.fulfilled, (state) => {
        state.loading.fetchBookings = false;
        state.success.cancelBooking =
          messageConstants.DEFAULT_SUCCESS.CANCEL_BOOKING;
      })
      .addCase(clientActions.cancelBookingById.rejected, (state, action) => {
        state.loading.fetchBookings = false;
        state.error.cancelBooking =
          action?.payload?.message ||
          messageConstants.DEFAULT_ERROR.CANCEL_BOOKING;
      });
  },
});

export const selectClient = (state) => state.client.client;
export const selectLoggedInClient = (state) => state.client.loggedInClient;
export const selectClientBookings = (state) => state.client.bookings;

// Selecting loading states
export const selectClientLoading = (state) => state.client.loading.client;
export const selectCreateRestaurantLoading = (state) =>
  state.client.loading.createRestaurant;
export const selectEditProfileLoading = (state) =>
  state.client.loading.editProfile;
export const selectRestaurantLoginLoading = (state) =>
  state.client.loading.restaurantLogin;
export const selectFetchBookingsLoading = (state) =>
  state.client.loading.fetchBookings;

// Selecting error messages
export const selectClientErrorMessage = (state) => state.client.error.client;
export const selectCreateRestaurantError = (state) =>
  state.client.error.createRestaurxant;
export const selectRestaurantLoginErrorMessage = (state) =>
  state.client.error.restaurantLogin;
export const selectClientUpdateGeneralInfoErrorMessage = (state) =>
  state.client.error.updateGeneralInfo;
export const selectMakeBookingErrorMessage = (state) =>
  state.client.error.makeBooking;
export const selectFetchBookingsErrorMessage = (state) =>
  state.client.error.fetchBookings;
export const selectCancelBookingByClientErrorMessage = (state) =>
  state.client.error.cancelBooking;

// Selecting success messages
export const selectCreateRestaurantSuccess = (state) =>
  state.client.success.createRestaurant;
export const selectRestaurantLoginSuccessMessage = (state) =>
  state.client.success.restaurantLogin;
export const selectClientUpdateGeneralInfoSuccessMessage = (state) =>
  state.client.success.updateGeneralInfo;
export const selectCancelBookingByClientSuccessMessage = (state) =>
  state.client.success.cancelBooking;

export const {
  setLoggedInClient,
  // Clear client info
  clearLoggedInClient,

  // Clear error messages
  clearClientErrorMessage,
  clearCreateRestaurantError,
  clearRestaurantLoginErrorMessage,
  clearClientUpdateGeneralInfoErrorMessage,
  clearMakeBookingErrorMessage,
  clearFetchBookingsErrorMessage,
  clearCancelBookingByClientErrorMessage,

  // Clear success messages
  clearCreateRestaurantSuccess,
  clearRestaurantLoginSuccessMessage,
  clearClientUpdateGeneralInfoSuccessMessage,
  clearBookingSuccessMessage,
  clearCancelBookingByClientSuccessMessage,
} = clientSlice.actions;

export default clientSlice.reducer;
