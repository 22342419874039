import _ from "lodash";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Divider,
  List,
  Modal,
  Rate,
  Space,
  Typography,
  Pagination,
  Alert,
} from "antd";
import {
  CopyFilled,
  DoubleRightOutlined,
  ExclamationCircleFilled,
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  LinkOutlined,
  ShareAltOutlined,
  SpotifyFilled,
  StarFilled,
  TwitterOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  capitalizeFirstLetter,
  createFullName,
  formatToRupee,
  getAwardsString,
  getEventTypesString,
  getFinalRating,
  getGenresString,
  getInstrumentsString,
  getLanguagesString,
} from "../../../../utils/common";
import {
  selectBookingSuccessMessage,
  selectLoggedInClient,
} from "../../../../reducers/client";
import { selectLoggedInArtist } from "../../../../reducers/artist";
import BookNowModal from "./BookNowModal";
import MusicIcon from "../../../Custom/Icon/MusicIcon";
import AboutItem from "../../../Custom/AboutItem";
import LanguageIcon from "../../../Custom/Icon/LanguageIcon";
import EventIcon from "../../../Custom/Icon/EventIcon";
import AwardIcon from "../../../Custom/Icon/AwardIcon";
import InstrumentIcon from "../../../Custom/Icon/InstrumentIcon";
import { ROUTES_MAP } from "../../../../constants/common";
import BookingSuccessModal from "./BookingSuccessModal";
import noprofilepicture from "../../../../images/noprofilepicture.jpeg";

const Artist = ({ artist }) => {
  const navigate = useNavigate();
  const loggedInClient = useSelector(selectLoggedInClient);
  const clientAddressesCount = loggedInClient?._count?.addresses;
  const loggedInArtist = useSelector(selectLoggedInArtist);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [isBookingSuccessModalOpen, setIsBookingSuccessModalOpen] =
    useState(false);
  const [reviewsCurrentPage, setReviewsCurrentPage] = useState(1);
  const [mediaCurrentPage, setMediaCurrentPage] = useState(1);
  const isOwnProfile = loggedInArtist?.id === artist?.id;

  // Handle page change
  const handleReviewsPageChange = (page) => {
    setReviewsCurrentPage(page);
  };

  const handleMediaPageChange = (page) => {
    setMediaCurrentPage(page);
  };

  const openBookingModal = () => {
    setIsBookingModalOpen(true);
  };

  const closeBookingModal = () => {
    setIsBookingModalOpen(false);
  };

  const openBookingSuccessModal = () => {
    setIsBookingSuccessModalOpen(true);
  };

  const closeBookingSuccessModal = () => {
    setIsBookingSuccessModalOpen(false);
  };

  const showNoAddressesWarning = () => {
    Modal.confirm({
      title: "No Addresses",
      icon: <ExclamationCircleFilled />,
      content:
        "You have to add an address to the venue for artist performance.",
      okText: "Add Address",
      cancelText: "Cancel",
      closable: true,
      onOk() {
        navigate(ROUTES_MAP.CLIENT_ADDRESSES.path);
      },
      onCancel() {},
    });
  };

  const onBookArtistClick = () => {
    if (!clientAddressesCount) {
      showNoAddressesWarning();
      return;
    }

    openBookingModal();
  };

  const reviews = artist?.reviews || [];
  const bookings = artist?.bookings || [];
  const bio = artist?.bio || "";
  const upcomingBooking = bookings?.find((booking) => booking);
  const medias = artist?.medias || [];
  const genres = artist?.genres || [];
  const languages = artist?.languages || [];
  const instruments = artist?.instruments || [];
  const eventTypes = artist?.eventTypes || [];
  const awards = artist?.awards || [];
  const termsAndConditions = artist?.termsAndConditions || [];
  const addressesCount = artist?._count?.addresses;

  // Calculate total pages based on the length of the array and reviewsPageSize
  const reviewsPageSize = 3;
  const totalReviews = reviews.length;
  const startReviewsIndex = (reviewsCurrentPage - 1) * reviewsPageSize;
  const paginatedReviews = reviews.slice(
    startReviewsIndex,
    startReviewsIndex + reviewsPageSize
  );

  // Calculate total pages based on the length of the array and galleryPageSize
  const mediaPageSize = 3;
  const totalMedia = medias.length;
  const startMediaIndex = (mediaCurrentPage - 1) * mediaPageSize;
  const paginatedMedia = medias.slice(
    startMediaIndex,
    startMediaIndex + mediaPageSize
  );

  const addressSection = () => {
    if (!isOwnProfile) return;
    if (addressesCount > 0) return;
    return (
      <div className="px-8 pt-16 w-9/10 md:w-full">
        <Alert
          className="w-fit"
          message="You don't have any addresses added. Adding address is mandatory for client booking the artist."
          type="error"
        />
      </div>
    );
  };

  const aboutSection = () => {
    const isAboutSectionEmpty =
      _.isEmpty(genres) &&
      _.isEmpty(eventTypes) &&
      _.isEmpty(awards) &&
      _.isEmpty(instruments) &&
      _.isEmpty(languages);
    const incompleteSections = _.compact([
      _.isEmpty(genres) && "Genres",
      _.isEmpty(eventTypes) && "Event Types",
      _.isEmpty(awards) && "Awards",
      _.isEmpty(instruments) && "Instruments",
      _.isEmpty(languages) && "Languages",
    ]).join(", ");

    const renderAboutItem = (title, content, icon) => (
      <Card.Grid className="w-full text-left md:w-1/2" hoverable={false}>
        <AboutItem icon={icon} title={title} content={content} />
      </Card.Grid>
    );

    if (!isAboutSectionEmpty) {
      return (
        <div className="px-8 pt-16 w-9/10 md:w-full">
          <Divider orientation="left">
            <Typography.Title level={3}>About</Typography.Title>
          </Divider>
          {isOwnProfile && incompleteSections && (
            <div className="w-full mb-10">
              <Alert
                className="w-fit"
                type="info"
                message={
                  <Fragment>
                    <div>
                      Please fill out the following sections on the{" "}
                      <span
                        className="font-bold underline hover:cursor-pointer"
                        onClick={() =>
                          navigate(ROUTES_MAP.ARTIST_EDIT_PROFILE.path)
                        }
                      >
                        Edit Profile
                      </span>{" "}
                      page:
                    </div>
                    <div>{`${incompleteSections}.`}</div>
                  </Fragment>
                }
              />
            </div>
          )}
          <div className="w-full">
            <Card
              className="w-full transition-shadow duration-300 shadow-sm"
              bordered
            >
              {!_.isEmpty(genres) &&
                renderAboutItem(
                  "Genre",
                  getGenresString(genres),
                  <MusicIcon />
                )}
              {!_.isEmpty(eventTypes) &&
                renderAboutItem(
                  "Event",
                  getEventTypesString(eventTypes),
                  <EventIcon />
                )}
              {!_.isEmpty(awards) &&
                renderAboutItem(
                  "Awards",
                  getAwardsString(awards),
                  <AwardIcon />
                )}
              {!_.isEmpty(instruments) &&
                renderAboutItem(
                  "Instruments",
                  getInstrumentsString(instruments),
                  <InstrumentIcon />
                )}
              {!_.isEmpty(languages) &&
                renderAboutItem(
                  "Languages",
                  getLanguagesString(languages),
                  <LanguageIcon />
                )}
            </Card>
          </div>
        </div>
      );
    }

    if (isOwnProfile) {
      return (
        <div className="px-8 pt-16 w-9/10 md:w-full">
          <Divider orientation="left">
            <Typography.Title level={3}>About</Typography.Title>
          </Divider>
          <div className="w-full">
            <Alert
              className="w-fit"
              type="info"
              message={
                <span>
                  Please fill your About Section by editing the profile in{" "}
                  <span
                    className="font-bold underline hover:cursor-pointer"
                    onClick={() =>
                      navigate(ROUTES_MAP.ARTIST_EDIT_PROFILE.path)
                    }
                  >
                    Edit Profile
                  </span>{" "}
                  page.
                </span>
              }
            />
          </div>
        </div>
      );
    }

    return null;
  };

  const bioSection = () => {
    if (!_.isEmpty(bio)) {
      return (
        <div className="w-full px-8 pt-16 md:w-full">
          <Divider orientation="left">
            <Typography.Title level={3}>Bio</Typography.Title>
          </Divider>
          <div className="w-full px-8">
            <Typography.Paragraph className="m-0 text-slate-500">
              {bio}
            </Typography.Paragraph>
          </div>
        </div>
      );
    }

    // If it's the user's own profile and the bio is empty
    if (isOwnProfile) {
      return (
        <div className="w-full px-8 pt-16 md:w-full">
          <Divider orientation="left">
            <Typography.Title level={3}>Bio</Typography.Title>
          </Divider>
          <div className="w-full">
            <Alert
              className="w-fit"
              type="info"
              message={
                <span>
                  You have not filled your Bio. Please update it in the{" "}
                  <span
                    className="font-bold underline hover:cursor-pointer"
                    onClick={() =>
                      navigate(ROUTES_MAP.ARTIST_EDIT_PROFILE.path)
                    }
                  >
                    Edit Profile
                  </span>{" "}
                  page.
                </span>
              }
            />
          </div>
        </div>
      );
    }

    // Return null if bio is empty and it's not the user's own profile
    return null;
  };

  const gallerySection = () => {
    const isGalleryEmpty = _.isEmpty(medias);

    const renderMediaItem = (media) => (
      <Space
        key={media.id} // Assuming each media has a unique id
        direction="vertical"
        size="small"
        className="relative w-full"
        style={{ paddingBottom: "56.25%" }}
      >
        <iframe
          className="absolute top-0 left-0 w-full h-full rounded-md"
          src={media.link}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </Space>
    );

    if (!isGalleryEmpty) {
      return (
        <div className="w-full px-8 pt-16 md:w-full">
          <Divider orientation="left">
            <Typography.Title level={3}>Gallery</Typography.Title>
          </Divider>
          <div className="flex flex-col items-center w-full">
            <div className="w-full md:px-8 lg:w-4/5">
              {paginatedMedia.map((media) => renderMediaItem(media))}
            </div>
            {totalMedia > mediaPageSize && (
              <div className="flex justify-center w-full my-6">
                <Pagination
                  current={mediaCurrentPage}
                  total={totalMedia}
                  pageSize={mediaPageSize}
                  onChange={handleMediaPageChange}
                />
              </div>
            )}
          </div>
        </div>
      );
    } else if (isOwnProfile) {
      return (
        <div className="w-full px-8 pt-16 md:w-full">
          <Divider orientation="left">
            <Typography.Title level={3}>Gallery</Typography.Title>
          </Divider>
          <div className="w-full">
            <Alert
              className="w-fit"
              type="info"
              message={
                <span>
                  Your gallery is empty. Please add media by editing your
                  profile in the{" "}
                  <span
                    className="font-bold underline hover:cursor-pointer"
                    onClick={() =>
                      navigate(ROUTES_MAP.ARTIST_EDIT_PROFILE.path)
                    }
                  >
                    Edit Profile
                  </span>{" "}
                  page.
                </span>
              }
            />
          </div>
        </div>
      );
    }

    return null;
  };

  const termsAndConditionsSection = () => {
    const isTermsEmpty = _.isEmpty(termsAndConditions);

    if (!isTermsEmpty) {
      return (
        <div className="w-full px-8 pt-16 md:w-full">
          <Divider orientation="left">
            <Typography.Title level={3}>
              Artist Terms And Conditions
            </Typography.Title>
          </Divider>
          <div className="flex flex-col w-full">
            <div className="w-full md:px-8 lg:w-4/5">
              <List
                size="small"
                bordered
                dataSource={termsAndConditions.map(
                  (condition) => condition.content
                )}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </div>
          </div>
        </div>
      );
    } else if (isOwnProfile) {
      return (
        <div className="w-full px-8 pt-16 md:w-full">
          <Divider orientation="left">
            <Typography.Title level={3} className="text-nowrap">
              Artist Terms And Conditions
            </Typography.Title>
          </Divider>
          <div className="w-full">
            <Alert
              className="w-fit"
              type="info"
              message={
                <span>
                  Your terms and conditions section is empty. Please add your
                  terms by editing your profile on the{" "}
                  <span
                    className="font-bold underline hover:cursor-pointer"
                    onClick={() =>
                      navigate(ROUTES_MAP.ARTIST_EDIT_PROFILE.path)
                    }
                  >
                    Edit Profile
                  </span>{" "}
                  page.
                </span>
              }
            />
          </div>
        </div>
      );
    }

    return null;
  };

  const reviewsSection = () => {
    const isReviewsEmpty = _.isEmpty(reviews);

    if (!isReviewsEmpty) {
      return (
        <div className="w-full px-8 pt-16 md:w-full">
          <Divider orientation="left">
            <Typography.Title level={3}>
              Reviews ({reviews.length})
            </Typography.Title>
          </Divider>

          <div className="flex flex-col justify-center w-full px-8">
            {paginatedReviews.map((review, index) => (
              <Space key={review.id} direction="vertical" size="small">
                <Rate allowHalf disabled value={review.rating} />
                <Typography.Paragraph className="m-0 font-medium">
                  {createFullName(review.client)}
                </Typography.Paragraph>
                <Typography.Paragraph className="m-0 text-slate-500">
                  {review.comment}
                </Typography.Paragraph>
                {index !== paginatedReviews.length - 1 && <Divider />}
              </Space>
            ))}
            {totalReviews > reviewsPageSize && (
              <div className="flex justify-center w-full my-6">
                <Pagination
                  current={reviewsCurrentPage}
                  total={totalReviews}
                  pageSize={reviewsPageSize}
                  onChange={handleReviewsPageChange}
                />
              </div>
            )}
          </div>
        </div>
      );
    } else if (isOwnProfile) {
      return (
        <div className="w-full px-8 pt-16 md:w-full">
          <Divider orientation="left">
            <Typography.Title level={3}>Reviews</Typography.Title>
          </Divider>
          <div className="w-full">
            <Alert
              className="w-fit"
              type="info"
              message="You have no reviews yet. Encourage your clients to leave feedback by sharing your profile."
            />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex flex-col w-full pb-20 md:flex-row">
      <BookNowModal
        artist={artist}
        isModalOpen={isBookingModalOpen}
        handleCancel={closeBookingModal}
        handleOk={openBookingModal}
        openBookingSuccessModal={openBookingSuccessModal}
      />
      <BookingSuccessModal
        open={isBookingSuccessModalOpen}
        closeModal={closeBookingSuccessModal}
      />
      <div className="flex flex-col items-center w-full mt-10 md:w-2/5">
        <div className="sticky top-16">
          <Card
            className="w-[260px]"
            cover={
              <img
                alt="Artist"
                src={
                  _.isEmpty(artist?.profilePictureURL)
                    ? noprofilepicture
                    : artist.profilePictureURL
                }
              />
            }
          >
            <Card.Meta
              className="w-full"
              title={
                <Typography.Paragraph
                  className="flex items-center gap-2 m-0 text-base"
                  copyable={{
                    icon: [
                      <ShareAltOutlined className="text-xl" key="copy-icon" />,
                      <CopyFilled className="text-xl" key="copied-icon" />,
                    ],
                    tooltips: [
                      "Click to copy profile link!",
                      "Artist Profile link copied!",
                    ],
                    text: window.location.href,
                  }}
                >
                  {createFullName(artist)}
                </Typography.Paragraph>
              }
              description={
                <div className="flex flex-col items-start">
                  <Typography.Paragraph
                    type="secondary"
                    className={artist?.price ? "mb-1" : "mb-2"}
                  >
                    <UserOutlined /> {capitalizeFirstLetter(artist?.type?.name)}
                  </Typography.Paragraph>
                  {artist?.price &&
                    (loggedInClient || loggedInArtist?.id === artist?.id) && (
                      <Typography.Paragraph type="secondary" className="mb-2">
                        {formatToRupee(artist.price)} / Event onwards
                      </Typography.Paragraph>
                    )}
                  {!_.isEmpty(reviews) && (
                    <Rate allowHalf disabled value={getFinalRating(reviews)} />
                  )}
                  {loggedInClient ? (
                    <Fragment>
                      <Divider />
                      <div className="flex justify-end w-full gap-4">
                        <Button type="primary" onClick={onBookArtistClick}>
                          Book Artist
                        </Button>
                      </div>
                    </Fragment>
                  ) : loggedInArtist?.id !== artist?.id ? (
                    <Fragment>
                      <Divider />
                      <div className="flex justify-center w-full gap-4">
                        <Typography.Paragraph>
                          Login as Client to check prices!
                        </Typography.Paragraph>
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              }
            />
          </Card>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center w-full md:w-3/5">
        {addressSection()}
        {aboutSection()}
        {bioSection()}
        {gallerySection()}
        {termsAndConditionsSection()}
        {reviewsSection()}
      </div>
    </div>
  );
};

export default Artist;
