import _ from "lodash";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CheckCircleOutlined,
  CompassOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Rate, Typography } from "antd";
import {
  createFullName,
  formatToRupee,
  getFinalRating,
} from "../../utils/common";
import { selectLoggedInClient } from "../../reducers/client";
import noprofilepicture from "../../images/noprofilepicture.jpeg";

const Artist = ({ artist }) => {
  const navigate = useNavigate();
  const loggedInClient = useSelector(selectLoggedInClient);
  const artistCity = artist?.addresses?.[0]?.city?.name;

  const handleSubmit = () => {
    navigate(`/artist/${artist.id}`);
  };

  const bookings = artist?._count?.bookings;

  return (
    <Card
      hoverable
      onClick={handleSubmit}
      style={{
        width: 260,
      }}
      cover={
        <img alt="example" src={artist.profilePictureURL ?? noprofilepicture} />
      }
    >
      <Card.Meta
        className="w-[210px]"
        title={createFullName(artist)}
        description={
          <div className="flex flex-col items-start">
            <Typography.Paragraph
              type="secondary"
              className={artist?.price ? "mb-1" : "mb-2"}
            >
              <UserOutlined /> {artist?.type?.name}
              {bookings > 0 && (
                <Fragment>
                  {" "}
                  &nbsp; | &nbsp; <CheckCircleOutlined /> {bookings} Booking
                  {bookings > 1 ? "s" : ""}
                </Fragment>
              )}
            </Typography.Paragraph>
            {artist?.price && loggedInClient && (
              <Typography.Paragraph type="secondary" className="mb-2">
                {formatToRupee(artist.price)} / Event onwards
              </Typography.Paragraph>
            )}
            {artistCity && (
              <Typography.Paragraph type="secondary" className="mb-2">
                <CompassOutlined /> {artistCity}
              </Typography.Paragraph>
            )}
            {!_.isEmpty(artist?.reviews) && (
              <Rate
                allowHalf
                disabled
                value={getFinalRating(artist?.reviews)}
              />
            )}
          </div>
        }
      />
    </Card>
  );
};

export default Artist;
