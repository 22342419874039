import { useNavigate } from "react-router-dom";
import { Modal, Typography } from "antd";
import { ROUTES_MAP } from "../../../constants/common";
import work from "../../../images/icons/work.png";
import guitar from "../../../images/icons/guitar.png";

const LoginModal = ({ isLogin, isModalOpen, closeModal }) => {
  const navigate = useNavigate();

  const onArtistSignup = () => {
    closeModal();
    navigate(ROUTES_MAP.ARTIST_SIGNUP.path);
  };

  const onClientSignup = () => {
    closeModal();
    navigate(ROUTES_MAP.CLIENT_SIGNUP.path);
  };

  const onArtistLogin = () => {
    closeModal();
    navigate(ROUTES_MAP.ARTIST_LOGIN.path);
  };

  const onClientLogin = () => {
    closeModal();
    navigate(ROUTES_MAP.CLIENT_LOGIN.path);
  };

  return (
    <Modal
      title={isLogin ? "Login" : "Signup"}
      open={isModalOpen}
      onCancel={closeModal}
      footer={null}
      centered
    >
      <div className="flex justify-center p-4">
        <div className="flex gap-12">
          <div
            className="flex flex-col items-center gap-2 p-5 rounded-lg hover:shadow-xl hover:cursor-pointer"
            onClick={isLogin ? onClientLogin : onClientSignup}
          >
            <img className="w-[80px]" src={work} alt="Client" />
            <Typography.Paragraph className="m-0">
              I'm a Client
            </Typography.Paragraph>
          </div>
          <div
            className="flex flex-col items-center gap-2 p-5 rounded-lg hover:shadow-xl hover:cursor-pointer"
            onClick={isLogin ? onArtistLogin : onArtistSignup}
          >
            <img className="w-[80px]" src={guitar} alt="Client" />
            <Typography.Paragraph className="m-0">
              I'm an Artist
            </Typography.Paragraph>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
