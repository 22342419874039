import { Divider, Space, Typography } from "antd";
import {
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import commonConstants from "../../constants/common";
import artistNetwork from "../../images/theartistnetwork.png";

const Footer = () => {
  return (
    <div className="w-full flex justify-center mb-12">
      <div className="md:w-3/4 w-4/5">
        <Divider />

        <div className="w-full md:h-[30vh] flex md:flex-row flex-col-reverse justify-around items-center">
          <div className="md:w-2/3 w-full h-full flex flex-col justify-center items-center">
            <img
              className="w-[300px]"
              src={artistNetwork}
              alt="The Artist Network"
            />
            <Typography.Paragraph className="text-center text-slate-400">
              Hire the best artists from our talented network
            </Typography.Paragraph>
            <Space size="middle">
              <a
                className="no-underline text-inherit cursor-auto"
                href={commonConstants.SOCIAL_MEDIA.TWITTER.LINK}
                target="_blank"
              >
                <TwitterOutlined className="hover:cursor-pointer" />
              </a>
              <a
                className="no-underline text-inherit cursor-auto"
                href={commonConstants.SOCIAL_MEDIA.INSTAGRAM.LINK}
                target="_blank"
              >
                <InstagramOutlined className="hover:cursor-pointer" />
              </a>
              <a
                className="no-underline text-inherit cursor-auto"
                href={commonConstants.SOCIAL_MEDIA.FACEBOOK.LINK}
                target="_blank"
              >
                <FacebookFilled className="hover:cursor-pointer" />
              </a>
              <a
                className="no-underline text-inherit cursor-auto"
                href={commonConstants.SOCIAL_MEDIA.LINKEDIN.LINK}
                target="_blank"
              >
                <LinkedinFilled className="hover:cursor-pointer" />
              </a>
              <a
                className="no-underline text-inherit cursor-auto"
                href={commonConstants.SOCIAL_MEDIA.YOUTUBE.LINK}
                target="_blank"
              >
                <YoutubeOutlined className="hover:cursor-pointer" />
              </a>
            </Space>
          </div>

          <div className="w-full flex md:flex-row flex-col md:justify-around md:text-left text-center md:gap-0 gap-4">
            <Space direction="vertical" size="small">
              <Typography.Paragraph className="m-0" strong>
                Support
              </Typography.Paragraph>
              <Typography.Paragraph className="m-0 text-slate-400">
                FAQ
              </Typography.Paragraph>
              <Typography.Paragraph className="m-0 text-slate-400">
                Contact Us
              </Typography.Paragraph>
            </Space>

            <Space direction="vertical" size="small">
              <Typography.Paragraph className="m-0" strong>
                Company
              </Typography.Paragraph>
              <Typography.Paragraph className="m-0 text-slate-400">
                Home
              </Typography.Paragraph>
              <Typography.Paragraph className="m-0 text-slate-400">
                Blog
              </Typography.Paragraph>
              <Typography.Paragraph className="m-0 text-slate-400">
                About Us
              </Typography.Paragraph>
              <Typography.Paragraph className="m-0 text-slate-400">
                Testimonials
              </Typography.Paragraph>
            </Space>

            <Space direction="vertical" size="small">
              <Typography.Paragraph className="m-0" strong>
                Legal
              </Typography.Paragraph>
              <Typography.Paragraph className="m-0 text-slate-400">
                Privacy Policy
              </Typography.Paragraph>
              <Typography.Paragraph className="m-0 text-slate-400">
                Disclaimer
              </Typography.Paragraph>
              <Typography.Paragraph className="m-0 text-slate-400">
                Terms & Conditions
              </Typography.Paragraph>
            </Space>
          </div>
        </div>

        <Divider />

        <div className="w-full flex justify-center text-center">
          <Typography.Paragraph className="m-0 text-slate-400">
            © 2024 The Artist Network. All rights reserved.
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  );
};

export default Footer;
