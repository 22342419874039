import _ from "lodash";
import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  DatePicker,
  Form,
  Input,
  List,
  Modal,
  Select,
  Tooltip,
  Typography,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  selectBookNowLoading,
  selectEventTypes,
} from "../../../../reducers/artist";
import {
  selectAddresses,
  selectFetchAddressesLoading,
} from "../../../../reducers/address";
import { constructAddress, formatToRupee } from "../../../../utils/common";
import { fetchAddressesByClientId } from "../../../../actions/address";
import { selectArtistAvailabilitiesLoading } from "../../../../reducers/artist";
import { selectLoggedInClient } from "../../../../reducers/client";
import { ROUTES_MAP } from "../../../../constants/common";
import { makeBooking } from "../../../../actions/client";
import { fetchMetadata } from "../../../../actions/artist";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const BookNowModal = ({
  artist,
  isModalOpen,
  handleCancel,
  openBookingSuccessModal,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const client = useSelector(selectLoggedInClient);
  const addresses = useSelector(selectAddresses);
  const bookNowLoading = useSelector(selectBookNowLoading);
  const fetchAddressesLoading = useSelector(selectFetchAddressesLoading);
  const fetchAvailabilitiesLoading = useSelector(
    selectArtistAvailabilitiesLoading
  );
  const price = artist?.price;
  const termsAndConditions = artist?.termsAndConditions;

  useEffect(() => {
    if (!_.isEmpty(client?.id)) dispatch(fetchAddressesByClientId(client.id));
    //dispatch(fetchAvailabilityByArtistId(artist?.id));
  }, []);

  const handleBooking = async (values) => {
    try {
      const [startTime, endTime] = values.eventTime;
      const result = await dispatch(
        makeBooking({
          price,
          clientId: client?.id,
          artistId: artist?.id,
          eventType: values.eventType,
          eventDescription: values.eventDescription,
          addressId: values.addressId,
          startTime,
          endTime,
        })
      );

      if (makeBooking.fulfilled.match(result)) {
        openBookingSuccessModal();
        handleCancel();
      }
    } catch (e) {}
  };

  const handleSubmit = (values) => {
    Modal.confirm({
      title: "Artist Terms and Conditions!",
      content: (
        <div className="w-full">
          <List
            size="small"
            bordered
            dataSource={termsAndConditions.map(
              (condition) => condition.content
            )}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </div>
      ),
      okText: `Request Booking ${formatToRupee(price)}`,
      cancelText: "Back",
      onOk() {
        handleBooking(values);
      },
      onCancel() {},
    });
  };

  const disabledDate = (current) => {
    // Disable past dates and dates more than 3 months from today
    const today = moment().startOf("day");
    const twoDaysLater = today.add(2, "days");
    const threeMonthsLater = moment().add(3, "months");

    // Check if the current date is before two days from now or after three months from now
    return (
      current &&
      (current.isBefore(twoDaysLater, "day") ||
        current.isAfter(threeMonthsLater, "day"))
    );
  };

  const handleClose = () => {
    handleCancel();
  };

  return (
    <Modal
      title="Book Now"
      loading={
        bookNowLoading || fetchAddressesLoading || fetchAvailabilitiesLoading
      }
      open={isModalOpen}
      onCancel={handleClose}
      okText={`Request Booking ${formatToRupee(price)}`}
      cancelText="Cancel"
      destroyOnClose
      centered
      okButtonProps={{ autoFocus: true, htmlType: "submit" }}
      modalRender={(dom) => (
        <Form
          className="mt-6"
          layout="vertical"
          form={form}
          name="bookNowModal"
          requiredMark="optional"
          clearOnDestroy
          onFinish={handleSubmit}
        >
          {dom}
        </Form>
      )}
    >
      <Form.Item
        className="mb-2"
        name="addressId"
        label="Venue Address"
        rules={[
          {
            required: true,
            message: "Please select your Venue Address!",
          },
        ]}
        extra="Artist will perform on this address."
      >
        <Select
          style={{ width: "100%" }}
          placeholder="XYZ Restaurant, 500XXX, Hyderabad"
          popupMatchSelectWidth={false}
        >
          {addresses
            .filter((address) => !_.isEmpty(constructAddress(address)))
            .map((address) => (
              <Option value={address.id} key={address.id}>
                <Tooltip title={constructAddress(address)}>
                  <span className="block w-full truncate">
                    {constructAddress(address)}
                  </span>
                </Tooltip>
              </Option>
            ))}
        </Select>
      </Form.Item>

      <div className="flex justify-end">
        <Button
          icon={<PlusOutlined />}
          onClick={() => navigate(ROUTES_MAP.CLIENT_ADDRESSES.path)}
        >
          Add Address
        </Button>
      </div>

      <Form.Item
        name="eventType"
        label="Event Type"
        rules={[{ required: true, message: "Please enter the event type!" }]}
      >
        <Input placeholder="Wedding / Birthday Party" />
      </Form.Item>

      <Form.Item
        name="eventDescription"
        label="Event Description"
        rules={[
          { required: true, message: "Please enter the event description!" },
        ]}
      >
        <TextArea
          type="textarea"
          rows={3}
          maxLength={200}
          placeholder="Perform for the night in our restaurent."
        />
      </Form.Item>

      <Form.Item
        name="eventTime"
        label="Event Time"
        rules={[{ required: true, message: "Please enter the event timing!" }]}
        extra="Artist will perform from start time to end time"
      >
        <RangePicker
          style={{ width: "100%" }}
          disabledDate={disabledDate}
          //onChange={onRangeSelection}
          use12Hours
          minuteStep={15}
          format="DD/MM/YYYY hh:mm A"
          showTime
        />
      </Form.Item>
    </Modal>
  );
};

export default BookNowModal;
