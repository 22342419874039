import { Card, Divider, Typography } from "antd";

const Contact = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full my-10">
      <div className="flex flex-col items-center w-4/5">
        <Divider orientation="left">
          <Typography.Title level={3}>Contact Us</Typography.Title>
        </Divider>

        <Card className="w-full md:w-1/2">
          <div className="w-full mb-10 text-center">
            <Typography.Paragraph type="secondary">
              You can use the following information to reach out to us regarding
              bookings, how we work and what we do.
            </Typography.Paragraph>
          </div>
          <div className="flex justify-between w-full">
            <Typography.Paragraph>Phone Number -</Typography.Paragraph>
            <Typography.Paragraph>+917972231631</Typography.Paragraph>
          </div>
          <div className="flex justify-between w-full">
            <Typography.Paragraph>Email -</Typography.Paragraph>
            <Typography.Paragraph>
              media@theartistnetwork.in
            </Typography.Paragraph>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Contact;
